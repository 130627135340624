<template>
  <v-app>
    <router-view v-if="$route.meta.auth === false"></router-view>
    <div class="main-outer-blk" >
      <router-view v-if="$route.meta.auth === true"></router-view>
    </div>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
import Login from "@/components/Login/Login";
import { app as firebaseApp } from "./firebase";
import {
  getToken,
  getMessaging,
  onMessage,
  deleteToken,
} from "firebase/messaging";
import axios from "axios";
import { register as registerSW } from "register-service-worker";
export default {
  name: 'App',

  components: {
    Login,
    // HelloWorld,
  },

  data: () => ({
    messaging: null,
    clientToken: null,
    statusMessage: null,
  }),
  async created() {
    this.messaging = getMessaging(firebaseApp);
    if (this.isSubscribed()) this.clientToken = await this.getSubscribedToken();
    await this.registerServiceWorker();
  },
  methods: {
    async registerServiceWorker() {
      let subscribeToken = this.subscribeToken;
      if ("Notification" in window && navigator.serviceWorker) {
        registerSW(`${process.env.BASE_URL}firebase-messaging-sw.js`, {
          ready(reg) {
            console.log("Service worker is Ready");
            // subsctibe to FCM
            subscribeToken(reg);
          },
          registered(reg) {
            console.log("Service worker has been registered.");
            setInterval(() => {
              reg.update();
            }, 1000); // 30 min checks
          },
          cached() {
            // console.log('Content has been cached for offline use.');
          },
          updatefound() {
            console.log("New content is downloading.");
          },
          updated(reg) {
            console.log("New content is available; please refresh.");
            document.dispatchEvent(
                new CustomEvent("swUpdated", { detail: reg.waiting })
            );
          },
          offline() {
            console.log("No internet connection found.");
          },
          error(error) {
            console.error("Error during service worker registration:", error);
          },
        });
      }
    },
    async subscribeToken(reg) {
      let vapidKey = process.env.VAPIDKEY;
      console.log("vapidKey")
      console.log(vapidKey)
      try {
        let token = await getToken(this.messaging, {
          vapidKey,
          serviceWorkerRegistration: reg,
        });

        console.log("Client token => ", token);
        localStorage.setItem("clientToken",token)

        // send token to server
        // let res = await this.sendTokenToServer(token);
        // console.log("Sent to server", res.data);

        // store returned uuid with token
        // this.setSubscribedToken(res.data);

        this.listenForegroundMessage(reg);
      } catch (err) {
        console.error(err);
        this.unsetSubscribedToken();
        console.log("Retry to subscribe");
      }
    },
    async unsubscribeToken() {
      try {
        let unsubscribe = await deleteToken(this.messaging);
        console.log("Subscribed  ", unsubscribe);
        let res = await this.removeTokenFromServer(this.clientToken.uuid);
        console.log("Unsubscribed from server", res.data);
        await this.unsetSubscribedToken();
      } catch (err) {
        console.error(err);
      }
    },
    async listenForegroundMessage(reg) {
      if (!reg)
        reg = await navigator.serviceWorker.getRegistration(
            `${process.env.BASE_URL}firebase-messaging-sw.js`
        );

      onMessage(this.messaging, (payload) => {
        console.log("Message received. ", payload);
        let { notification, data } = payload;
        let notificationTitle = "Test title";
        let notificationBody = "Test body";

        if (notification && notification.title && notification.body) {
          notificationTitle = notification.title;
          notificationBody = notification.body;
        } else if (data && data.title && data.body) {
          notificationTitle = data.title;
          notificationBody = data.body;
        }

        // in window noti
        // this.$notify({
        //   group: "test",
        //   title: "[Foreground] " + notificationTitle,
        //   text: notificationBody,
        //   duration: 10000,
        // });

        const notificationOptions = {
          body: notificationBody,
        };
        if (reg)
          reg.showNotification(
              "[Foreground] " + notificationTitle,
              notificationOptions
          );
      });
    },
    isSubscribed() {
      let ct = localStorage.getItem("clientToken");
      console.log("Subscribed", !!ct);
      return !!ct;
    },
    setSubscribedToken({ data }) {
      this.clientToken = data;
      console.log("stored in localstorage", this.clientToken);
      localStorage.setItem("clientToken", JSON.stringify(this.clientToken));
    },
    unsetSubscribedToken() {
      this.clientToken = null;
      localStorage.removeItem("clientToken");
      console.log("removed from localstorage", this.clientToken);
    },
    async getSubscribedToken() {
      let ct = localStorage.getItem("clientToken");
      console.log(ct)
      if (ct) return ct;
      else return null;
    },
  }
};
</script>

<style lang="scss">
@import "scss/common";
@import url('https://fonts.googleapis.com/css?family=Rokkitt:100,200,300,400,500,600,700,800,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap');
#app {
  height: 100vh;
  font-family: 'Avenir',sans-serif;
  font-size: 15px;
}


//::-webkit-scrollbar {
//  width: 0;
//}
@media only screen and (min-width: 992px) {
  * {
    scrollbar-width: thin;
    scrollbar-color: #d4d4d4 #f7f7f7;
  }

  /* Works on Chrome/Edge/Safari */
  *::-webkit-scrollbar {
    width: 10px;
  }
  *::-webkit-scrollbar-track {
    background: #f7f7f7;
    width: 10px;
  }
  *::-webkit-scrollbar-thumb {
    width: 10px;
    background-color: #6d6d6d;
  }

  *::-webkit-scrollbar:horizontal{
    height: 10px;
    background-color: #f7f7f7;
  }
  *::-webkit-scrollbar-thumb:horizontal{
    background: #6d6d6d;
    border-radius: 0;
  }
  html,
  body {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
