import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAAPrafCSKD_2iv4li8fSHPK1AtcpF6hko",
    authDomain: "taski-1481609058033.firebaseapp.com",
    databaseURL: "https://taski-1481609058033.firebaseio.com",
    projectId: "taski-1481609058033",
    storageBucket: "taski-1481609058033.appspot.com",
    messagingSenderId: "253907993760",
    appId: "1:253907993760:web:1a43ab51820dece73db5c5",
    measurementId: "G-T3XK16NG91"
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)